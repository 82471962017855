import * as React from "react";
import Navbar from "../components/navbar";
import Bio from "../components/bio";
import * as style from "./index.module.css";

// define constants
const NAME = "leo martinez";
const EMAIL = "bleo@mit.edu";

const IndexPage = () => {
  return (
    <div className={style.viewport}>
      <Navbar name={NAME} email={EMAIL}></Navbar>
      <Bio></Bio>
    </div>
  );
};

export default IndexPage;
