import * as React from "react";
import "./menu.css";
import { Link, navigate } from "gatsby";
import "animate.css";

function reroute(pageName) {
  navigate(`/${pageName}`);
}

const MenuItem = ({ item, setFade }) => {
  const [blinking, setBlinking] = React.useState(false);
  if (blinking) {
    return (
      <div className="item animate__animated animate__flash animate__faster">
        {item}
      </div>
    );
  } else {
    return (
      <div
        className="item"
        onClick={() => {
          setBlinking(true);
          setFade(false);
          setTimeout(() => {
            reroute(item.toLowerCase());
          }, 1700); // LMFAO SORRY FUTURE LEO I KNOW THIS IS DISGUSTING but its non-blocking kinda? just stupid (7-18-22)
        }}
      >
        {item}
      </div>
    );
  }
};

const SmallMenuItem = ({ item }) => {
  return (
    <div
      className="small animate__animated animate__flash animate__faster"
      onClick={() => reroute(item.toLowerCase())}
    >
      {item}
    </div>
  );
};

export { MenuItem, SmallMenuItem };
