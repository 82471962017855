import * as React from "react";
import * as biostyle from "./bio.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { MenuItem, SmallMenuItem } from "./menu_item";
import "./menu.css";
import "animate.css";

const menuItems = ["PROJECTS", "ABOUT"];
const menuItemsSmall = ["CONTACT", "RESUME"];
const Menu = ({ fadeIn, setFade }) => {
  return (
    <div
      className={`menu ${
        fadeIn
          ? "animate__animated animate__fadeInLeft animate__delay-1s animate__faster"
          : "animate__animated animate__fadeOutLeft animate__delay-1s animate__faster"
      }`}
    >
      {menuItems.map((item, index) => (
        <MenuItem
          item={item}
          setFade={setFade}
          key={index}
          className="menu-items"
        />
      ))}
      {menuItemsSmall.map((item, index) => (
        <SmallMenuItem item={item} key={index} />
      ))}
    </div>
  );
};

export default Menu;
