import * as React from "react";
import * as biostyle from "./bio.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Script } from "gatsby";
import Menu from "../components/menu";
import "animate.css";

const location = "  Planet Earth, Milky Way Gxy";
const FIRST_NAME = "LEO";
const LAST_NAME = "MARTINEZ";
// const DESCRIPTION = "student. designer. innovator";
const IMAGE_SRC = "../images/weblomie.png";

const Bio = () => {
  const [fadeIn, setFadeIn] = React.useState(true);
  return (
    <div className={biostyle.bio}>
      <Script
        src="https://kit.fontawesome.com/cde1a27a61.js"
        crossorigin="anonymous"
      ></Script>
      <div
        className={`${biostyle.info} animate__animated ${
          fadeIn
            ? "animate__fadeIn animate__delay-1s"
            : "animate__fadeOut animate__delay-1s"
        }`}
      >
        <span className={biostyle.location_text}>
          <i className="fas fa-map-marker-alt fa-small" />
          {location}
        </span>
        <div className={biostyle.big_bold_text}>
          {FIRST_NAME}
          <br></br>
          {LAST_NAME}
        </div>
        {/* <div className={biostyle.DESCRIPTION_text}>{DESCRIPTION}</div> */}
        <Menu fadeIn={fadeIn} setFade={setFadeIn}></Menu>
      </div>

      <StaticImage
        className={`${biostyle.picture} animate__animated ${
          fadeIn
            ? "animate__fadeIn animate__faster"
            : "animate__fadeOut animate__delay-1s"
        }`}
        src={IMAGE_SRC}
        loading="eager"
      ></StaticImage>
    </div>
  );
};

export default Bio;
